// eslint-disable-next-line no-unused-vars
import _ from 'lodash'

import './modules/metrics/link-queries'
import './modules/metrics/gtm-events'
import './modules/scroll'
import './modules/mobile-menu'
import './modules/nav'
import './modules/faq'
import './modules/swiper-partners'
import './modules/swiper-reviews'
import './modules/scrollbars'
import './modules/scrollbars-reviews'
import './modules/popup'
import './modules/animation'
